<template>
  <div>
    <div v-if="showJob" class="automate-button">
      <b-button class="edit-button" @click="onCreateJobs(true)">
        <div class="edit-button-svg">Create Automate Jobs</div>
      </b-button>
    </div>
    <div class="clients-container">
      <modal-vue @onChange="onCreateJobs" :modal="openJobModal">
        <div class="job-delete-modal">
          <div class="job-delete-inside">
            <p class="modal-header">Automate Jobs</p>
            <div class="modal-data">
              <p>Are you sure you want to create automate jobs?</p>
              <div class="actions">
                <b-button @click="onCreateJobs(false)">Cancel</b-button>
                <b-button @click="automateJobs">Create</b-button>
              </div>
            </div>
          </div>
        </div>
      </modal-vue>
      <div class="user-table">
        <table-vue
          :labels="labels"
          :data="jobList"
          :dateFormat="dateFormat"
          :sortObj="sort"
          :totalCount="totalCount"
          :currentPage="currentPage"
          :limit="limit"
          :sortValidation="sortValidation"
          :titleData="'Jobs'"
          :showRefreshAction="editJob"
          :showEditAction="false"
          :showCancelAction="editJob"
          :showDeleteAction="false"
          :showAction="editJob"
          :showOptions="editJob"
          @onDelete="confirmDelete"
          @onAction="onAction"
          @searchText="searchText"
          @onChangeSort="onChangeSort"
          @updatePage="updatePage"
          @updateLimit="updateLimit"
          @onClickCell="handleOnClick">
          <div class="job-filters">
            <MultiSelectorVue
              label=""
              placeholder="Job Type"
              :selected="selectedJobType"
              :list="jobTypeList"
              @onChange="onChangeJobTypeSelection" />
            <MultiSelectorVue
              label=""
              placeholder="Priority"
              :selected="selectedPriorities"
              :list="priorityList"
              @onChange="onChangePrioritySelection" />
            <MultiSelectorVue
              label=""
              placeholder="Status"
              :selected="selectedStatuses"
              :list="statusList"
              @onChange="onChangeStatusSelection" />
          </div>

          <div class="refresh-button">
            <b-button class="edit-button" @click="getData(true)">
              <div class="edit-button-svg">
                <svg xmlns="http://www.w3.org/2000/svg" width="612.005" height="612.006" viewBox="0 0 612.005 612.006" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M605.731 267.776h-34.959C552.174 138.147 440.991 38.412 306.221 38.412c-104.15 0-194.175 59.635-238.404 146.487l71.791 27.6c32.742-58.22 95.033-97.633 166.614-97.633 92.471 0 169.595 65.675 187.294 152.909h-46.064c-5.905 5.734-11.563 9.29-3.096 19.229l71.312 72.517c5.906 5.734 15.482 5.734 21.389 0l71.791-72.517c5.886-5.733 2.789-13.475-3.117-19.228zm-299.51 229.365c-85.572 0-157.993-56.252-182.363-133.797h41.821c5.906-5.734 9.002-13.494 3.096-19.229l-71.771-72.517c-5.906-5.734-15.482-5.734-21.388 0L4.302 344.115c-8.468 9.939-2.81 13.494 3.096 19.229h35.055c20.738 113.383 130.756 210.25 263.787 210.25 97.346 0 182.286-52.16 229.115-129.877l-72.346-28.555c-34.557 49.524-91.841 81.979-156.788 81.979z"/></svg>
              </div>
            </b-button>
          </div>
        </table-vue>
      </div>
      <modal-vue @onChange="onChangeModal" :modal="modal">
        <div class="job-delete-modal">
          <div class="job-delete-inside">
            <p class="modal-header">{{ jobInfo ? jobInfo.type : '' }} Job</p>
            <div class="modal-data">
              <p>
                Are you sure you want to {{ jobInfo ? jobInfo.type : '' }} this
                Job (Id = {{ jobInfo ? jobInfo.object.job_id : '' }})?
              </p>
              <div class="actions">
                <b-button @click="onChangeModal(false)">Close</b-button>
                <b-button @click="handleAction">Confirm</b-button>
              </div>
            </div>
          </div>
        </div>
      </modal-vue>
      <modal-vue @onChange="onChangelogModal" :full-screen="true" :modal="logModal">
        <div class="job-log-modal">
          <div class="job-log-inside">
            <div class="modal-header">Job log details</div>
            <div v-if="loadingLogs">
              Loading...
            </div>
            <div v-else class="log">
              <div v-if="jobLog" style="white-space: pre;">
              {{ jobLog }}
              </div>
              <div v-else>
                No data
              </div>
            </div>
          </div>
        </div>
      </modal-vue>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import tableVue from '../../components/table.vue'
import {
  metaTitle,
  statusList,
  priorityList,
  jobTypeList
} from '../../../../constant/data'
import ModalVue from '../../../Admin/components/Modal.vue'
import MultiSelectorVue from '../../../../components/Dropdowns/MultiSelector.vue'

export default {
  name: 'Jobs',
  metaInfo () {
    return {
      title: metaTitle.jobs
    }
  },
  components: {
    tableVue,
    ModalVue,
    MultiSelectorVue
  },
  data () {
    return {
      labels: [
        { name: 'Job Id', alias: 'job_id' },
        { name: 'Job Details', alias: 'job_details', enableClick: true },
        { name: 'Status', alias: 'statusAlias' },
        { name: 'Priority', alias: 'priority', type: 'prioritySelector' },
        { name: 'Created By', alias: 'fullName' },
        { name: 'Updated At', alias: 'updated_at' }
      ],
      sortValidation: ['job_id', 'priority', 'updated_at'],
      dateFormat: ['updated_at'],
      search: '',
      sort: { by: 'asc', field: 'job_id' },
      modal: false,
      selectedStatuses: statusList,
      selectedPriorities: priorityList,
      selectedJobType: jobTypeList,
      jobTypeList,
      statusList,
      priorityList,
      jobInfo: null,
      openJobModal: false,
      deleteClientObject: {},
      logModal: false
    }
  },
  computed: {
    ...mapState('jobs', ['jobList', 'totalCount', 'jobLog', 'loadingLogs']),
    ...mapState('user', ['userDetails']),
    ...mapState('adminCommon', ['tableSelectionState', 'loader']),
    showJob () {
      if (
        [
          ...this.userDetails.permissions.filter(
            (obj) =>
              this.$router.history.current.path.includes(obj.path) && obj.write
          )
        ].length
      ) {
        return true
      }
      return false
    },
    editJob () {
      if (
        [
          ...this.userDetails.permissions.filter(
            (obj) =>
              this.$router.history.current.path.includes(obj.path) && obj.update
          )
        ].length
      ) {
        return true
      }
      return false
    },
    limit: {
      get () {
        return this.tableSelectionState
          ? this.tableSelectionState.jobs.limit
          : 10
      },
      set (newLimit) {
        this.tableSelectionState.jobs.limit = newLimit
      }
    },
    currentPage: {
      get () {
        return this.tableSelectionState
          ? this.tableSelectionState.jobs.currentPage
          : 1
      },
      set (newPage) {
        this.tableSelectionState.jobs.currentPage = newPage
      }
    }
  },
  mounted () {
    this.getData(false)
  },
  methods: {
    ...mapActions('jobs', [
      'getJobList',
      'updateJob',
      'resetJobList',
      'createAutomateJobs',
      'getLogDetails',
      'updateJobLog'
    ]),
    ...mapActions('adminCommon', ['toggleLoaderState']),
    async automateJobs () {
      this.toggleLoaderState()
      const res = await this.createAutomateJobs()
      if (res === 200) {
        this.toggleLoaderState()
        this.openJobModal = !this.openJobModal
      }
    },
    onChangeSort (type) {
      this.sort = type
      this.getData(false)
    },
    updatePage (data) {
      this.currentPage = data
      this.getData(false)
    },
    updateLimit (data) {
      this.limit = data
      this.getData(false)
    },
    searchText (text) {
      this.search = text
      if (this.search !== null) {
        this.currentPage = 1
        this.getData(false)
      }
    },
    onAction (type, object) {
      this.jobInfo = { type, object }
      this.modal = true
    },
    onChangeModal (value) {
      this.modal = value
    },
    onChangelogModal (value) {
      if (value === false) {
        this.updateJobLog('')
      }
      this.logModal = value
    },
    onCreateJobs (value) {
      this.openJobModal = value
    },
    onChangeStatusSelection (value) {
      this.selectedStatuses = value
      this.getData()
    },
    onChangePrioritySelection (value) {
      this.selectedPriorities = value
      this.getData()
    },
    onChangeJobTypeSelection (value) {
      this.selectedJobType = value
      this.getData()
    },
    confirmDelete (data) {
      this.jobInfo = data
      this.modal = true
    },
    handleOnClick (data) {
      this.getLogDetails(data)
      this.logModal = true
    },
    async handleAction () {
      const obj = {
        type: this.jobInfo.type,
        jobId: this.jobInfo.object.job_id
      }
      const res = await this.updateJob(obj)
      if (res === 200) {
        this.modal = false
      }
      // this.getData()
    },
    getData (type) {
      if (
        this.selectedStatuses.length &&
        this.selectedPriorities.length &&
        this.selectedJobType.length
      ) {
        this.getJobList({
          limit: this.limit,
          skip: (this.currentPage - 1) * this.limit,
          orderBy: this.sort,
          search: this.search,
          statusList: this.selectedStatuses,
          priorityList: this.selectedPriorities,
          jobTypeList: this.selectedJobType,
          type
        })
      } else {
        this.resetJobList()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.automate-button {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  .edit-button {
    background: var(--primary);
    height: 36px;
    padding: 3px 5px;
    font-size: 12px;
    border: var(--tertiary-border-color);
    color: var(--pagination-deactive-text-color);
    // background: transparent;
    .edit-button-svg {
      display: flex;
    }
    svg {
      outline: none;
      width: 20px;
      fill: var(--dropdown-images-backgroud);
      height: 20px;
    }
  }
}
.clients-container {
  margin: 10px 0px;
  border-radius: 4px;
  position: relative;
  background: var(--primary);
  .user-table {
    .refresh-button {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      .edit-button {
        width: 36px;
        height: 36px;
        padding: 3px;
        font-size: 12px;
        border: var(--tertiary-border-color);
        color: var(--pagination-deactive-text-color);
        background: transparent;
        .edit-button-svg {
          display: flex;
        }
        svg {
          outline: none;
          width: 20px;
          fill: var(--dropdown-images-backgroud);
          height: 20px;
        }
      }
    }
  }
  .job-delete-modal {
    display: flex;
    justify-content: center;
    .job-delete-inside {
      display: flex;
      color: var(--secondary-text-color);
      font-family: Quicksand;
      flex-direction: column;
      background: var(--dropdown-backgroud);
      border-radius: 4px;
      width: 400px;
      height: 180px;
      padding: 25px;
      .modal-data {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;
        p {
          font-size: 14px;
        }
      }
      .modal-header {
        font-size: 16px;
        font-weight: 500;
        padding-bottom: 10px;
        text-align: left;
      }
    }
    .actions {
      padding-top: 20px;
      text-align: end;
      .button {
        height: 32px;
        border: none;
        font-size: 14px;
        width: 75px;
      }
      .button:nth-child(1) {
        margin-left: 5px;
        background-color: transparent;
        color: var(--secondary-text-color);
        border: 1px solid var(--primary-border-color);
        &:hover {
          border: 2px solid var(--primary-border-color);
          transition: border 0.3s ease-out;
        }
        &:active {
          background-color: #7f8fa466;
          transition: background-color 0.3s ease-out;
        }
      }
      .button:nth-child(2) {
        margin-left: 5px;
        background-color: #ff0000;
        color: white;
        &:hover {
          background-color: #f64a4a;
          transition: background-color 0.3s ease-out;
        }
        &:active {
          background-color: #ee1111;
          transition: background-color 0.3s ease-out;
        }
      }
    }
  }
  .job-log-modal {
    display: flex;
    justify-content: center;
        width: 100%;
    // width: fit-content;
    .job-log-inside {
        width: 800px;
      display: flex;
      color: var(--secondary-text-color);
      font-family: Quicksand;
      flex-direction: column;
      background: var(--dropdown-backgroud);
      padding: 25px;
      border-radius: 4px;
      .modal-header {
        font-size: 16px;
        font-weight: 500;
        padding-bottom: 10px;
        text-align: left;
      }
      .log {
        display: flex;
        overflow: auto;
      }
    }
  }
}

.job-filters {
  display: flex;
  .data-source-container {
    margin-right: 10px;
  }
}
</style>
